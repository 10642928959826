import { Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerAuthService } from '@drklein-pk/customer-core-lib';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomerNameService } from '../../shell/customer-name.service';
import { InvitationService } from '../invitation.service';
import { Invite } from '../invite.model';

interface Code {
  character1: string;
  character2: string;
  character3: string;
  character4: string;
  character5: string;
  character6: string;
}

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnDestroy {

  @ViewChildren('codeInput') codeInputElements!: QueryList<ElementRef<HTMLInputElement>>;

  public twoFactorCodeFormGroup!: UntypedFormGroup;

  public customerName$: Observable<string>;

  public textCodeFormControl!: UntypedFormControl;

  public errorInState = false;

  public invitation$: Observable<Invite>;

  public loggedIn$: Observable<boolean>;

  public email: string | null = null;

  public firstName: string | null = null;

  public lastName: string | null = null;

  public emailConfirmationCode: string | null = null;

  private invitationId$: Observable<string>;

  private token$: Observable<string>;

  private _invitation = new ReplaySubject<Invite>(1);

  private _isDestroyedSubject = new Subject<void>();

  constructor(
    private authService: CustomerAuthService,
    private invitationService: InvitationService,
    private route: ActivatedRoute,
    private customerNameService: CustomerNameService,
  ) {
    this.customerName$ = customerNameService.customerName$;

    this.invitation$ = this._invitation.asObservable();

    this.invitationId$ = this.route.paramMap.pipe(
      filter(params => params.has('inviteId')),
      map(params => params.get('inviteId')!),
      distinctUntilChanged(),
    );

    this.token$ = this.route.queryParamMap.pipe(
      filter(query => query.has('token')),
      map(query => query.get('token')!),
      distinctUntilChanged(),
    );

    this.route.queryParamMap.subscribe(params => {
      this.email = params.get('email');
      this.firstName = params.get('firstName');
      this.lastName = params.get('lastName');
      this.emailConfirmationCode = params.get('emailConfirmationCode');
    });

    this.loggedIn$ = route.data.pipe(
      map((data) => data.userInfo),
      map(userInfo => userInfo != null),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          this.goToLogin();
        }
      }),
    );

    this.invitationId$.pipe(
      switchMap(id => invitationService.fetch(id)),
    ).subscribe(
      i => this._invitation.next(i),
      () => {
        this.errorInState = true;
      },
    );

    this.createFormGroup();
  }

  private get code(): string {
    return Object.entries(this.twoFactorCodeFormGroup.value).map(e => e[1]).join('');
  }

  public ngOnDestroy() {
    this._isDestroyedSubject.next();
    this._isDestroyedSubject.complete();
  }

  public goToLogin(): void {
    this.authService.redirectUri = window.location.href;
    const params: any = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      enable_registration: true,
      username: this.email ?? '',
      firstName: this.firstName ?? '',
      lastName: this.lastName ?? '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      if_unauthenticated: 'REGISTRATION',
    };
    if(this.emailConfirmationCode){
      params.email_confirmation_code = this.emailConfirmationCode;
    }
    this.authService.initImplicitFlow(undefined, params);
  }

  public sendTwoFactorCode() {
    combineLatest([this.invitationId$, this.token$]).pipe(
      switchMap(([id, token]) => this.invitationService.sendTwoFactor(id, token)),
    ).subscribe(
      i => this._invitation.next(i),
      () => {
        this.errorInState = true;
      },
    );
  }

  public trySubmit(sixCharacterInput = false) {
    if (!sixCharacterInput || this.code.length === 6) {
      this.confirm(sixCharacterInput);
    }
  }

  private confirm(sixCharacterInput: boolean) {
    combineLatest([this.invitationId$, this.token$]).pipe(
      switchMap(([id, token]) => this.invitationService.confirm(id, token, sixCharacterInput ? this.code : this.textCodeFormControl.value)),
    ).subscribe(
      i => {
        if (i.field === 'twoFactorCode') {
          this.errorInState = true;
        } else {
          this._invitation.next(i);
        }
      },
      () => {
        this.errorInState = true;
      },
    );
  }

  private createFormGroup() {
    const startValues: Code = {
      character1: '',
      character2: '',
      character3: '',
      character4: '',
      character5: '',
      character6: '',
    };
    this.twoFactorCodeFormGroup = new UntypedFormGroup({
      character1: new UntypedFormControl(startValues.character1),
      character2: new UntypedFormControl(startValues.character2),
      character3: new UntypedFormControl(startValues.character3),
      character4: new UntypedFormControl(startValues.character4),
      character5: new UntypedFormControl(startValues.character5),
      character6: new UntypedFormControl(startValues.character6),
    });
    this.textCodeFormControl = new UntypedFormControl('', [Validators.required]);
    this.twoFactorCodeFormGroup.valueChanges
      .pipe(
        takeUntil(this._isDestroyedSubject),
        startWith(startValues),
        pairwise(),
      )
      .subscribe((formValue) => this.handleValueChange(formValue[0], formValue[1]));
  }

  private handleValueChange(
    oldValue: Code,
    newValue: Code,
  ) {
    this.errorInState = false;
    const codeInputNativeElements = this.codeInputElements.toArray().map((e: ElementRef<HTMLInputElement>) => e.nativeElement);
    if (oldValue.character1 !== newValue.character1 && newValue.character1 !== '') {
      codeInputNativeElements[1].focus();
    } else if (oldValue.character2 !== newValue.character2 && newValue.character2 !== '') {
      codeInputNativeElements[2].focus();
    } else if (oldValue.character3 !== newValue.character3 && newValue.character3 !== '') {
      codeInputNativeElements[3].focus();
    } else if (oldValue.character4 !== newValue.character4 && newValue.character4 !== '') {
      codeInputNativeElements[4].focus();
    } else if (oldValue.character5 !== newValue.character5 && newValue.character5 !== '') {
      codeInputNativeElements[5].focus();
    } else if (oldValue.character6 !== newValue.character6 && newValue.character6 !== '') {
      this.trySubmit(true);
    }
  }
}
