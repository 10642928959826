import { Component, HostBinding, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';

import { NavigationService } from '../../core/navigation.service';

@Component({
  selector: 'customer-app-container',
  templateUrl: 'customer-app-container.component.html',
  styleUrls: ['customer-app-container.component.scss'],
})
export class CustomerAppContainerComponent implements OnDestroy {
  @HostBinding('class.navigation-visible') private _navigationIsOpen = false;

  private _active = true;

  constructor(public navigationService: NavigationService, private router: Router) {
    this.navigationService.navigationOpen$.pipe(takeWhile(() => this._active)).subscribe((isOpen) => {
      this._navigationIsOpen = isOpen;
    });
  }

  public ngOnDestroy() {
    this._active = false;
  }
}
