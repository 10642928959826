import { BusinessObject, INIT_BY_JSON_2_TS } from '@drklein-pk/customer-core-lib';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CustomerApp')
export class CustomerApp extends BusinessObject {
  @JsonProperty('id', String)
  public id: string = INIT_BY_JSON_2_TS;

  @JsonProperty('sortOrder', Number)
  public sortOrder: number = INIT_BY_JSON_2_TS;

  @JsonProperty('name', String)
  public name: string = INIT_BY_JSON_2_TS;

  @JsonProperty('displayName', String)
  public displayName: string = INIT_BY_JSON_2_TS;

  @JsonProperty('appUrl', String)
  public appUrl: string = INIT_BY_JSON_2_TS;

  @JsonProperty('description', String)
  public description: string = INIT_BY_JSON_2_TS;

  public config: CustomerAppConfig | null = null;

  public get iconUrl(): string {
    return `${this.appUrl}/app-icon.svg`;
  }
}

export interface CustomerAppConfig {
  route: string;
  elementName: string;
  styles?: string[];
  scripts: string[];
}
