<ng-container>
  <span id="customer-shell-logo" [class.clickable]="navigationActive" (click)="onLogoClick()">
    <img src="assets/drklein-logo.svg" alt="" />
  </span>

  <div
    *ngIf="navigationActive"
    id="customer-shell-user-salutation"
    [class.clickable]="navigationActive"
    (click)="onOpenMenu($event)"
  >
    <h4 class="mat-body-1 user-salutation" *ngIf="customerName$ | async as customerName">
      {{ 'header.greeting' | translate : { name: customerName } }}
    </h4>
    <div id="customer-shell-menu">
      <app-burger-menu></app-burger-menu>
    </div>
  </div>
</ng-container>
