import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_ID, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  CORE_CONFIG,
  CustomerCoreLibModule,
  DEFAULT_LANGUAGE,
  LANGUAGE_STORAGE_KEY,
  getStage,
  Stage
} from '@drklein-pk/customer-core-lib';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatomoModule } from 'ngx-matomo';
import { MatomoService } from './core/matomo.service';
import { APP_CONFIG, configFactory } from './app.config';
import { CustomerAppComponent } from './shell/app-container/customer-app/customer-app.component';
import { HeaderComponent } from './shell/header/header.component';
import { NavigationComponent } from './shell/navigation/navigation.component';
import { FooterComponent } from './shell/footer/footer.component';
import { CustomerAppContainerComponent } from './shell/app-container/customer-app-container.component';
import { AppComponent } from './app.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { BurgerMenuComponent } from './shell/header/burger-menu/burger-menu.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { InviteModule } from './invite/invite.module';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

const getSiteID = (): number => {
  // check stage and set corresponding matomoId
  const stage = getStage(window.location);
  // matomoSiteID is initialised with the prod trackingvalue
  let matomoSiteId = 6;
  if (stage === Stage.Prod) {
    matomoSiteId = 6;
  } else if (stage === Stage.Staging) {
    matomoSiteId = 8;
  } else if (stage === Stage.Dev) {
    matomoSiteId = 5;
  } else if (stage === Stage.Local) {
    matomoSiteId = 7;
  }
  return matomoSiteId;
};

@NgModule({
  declarations: [
    AppComponent,
    CustomerAppComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    CustomerAppContainerComponent,
    LoadingSpinnerComponent,
    BurgerMenuComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    CustomerCoreLibModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    InviteModule,
    MatomoModule.forRoot({
      scriptUrl: `${environment.matomoUrl}piwik.js`,
      trackers: [
        {
          trackerUrl: `${environment.matomoUrl}piwik.php`,
          siteId: getSiteID(),
        },
      ],
    }),
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Location,
    Title,
    DeviceDetectorService,
    MatomoService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: APP_CONFIG,
      useFactory: configFactory,
    },
    {
      provide: CORE_CONFIG,
      useExisting: APP_CONFIG,
    },
    {
      provide: APP_ID,
      useValue: 'customer-portal',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor() {
    // add storage key for better discoverability
    if (localStorage.getItem(LANGUAGE_STORAGE_KEY) === null) {
      localStorage.setItem(LANGUAGE_STORAGE_KEY, DEFAULT_LANGUAGE);
    }

  }
}
