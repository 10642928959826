<div class="agent-wrapper mat-body-1">
  <div class="title">{{ 'agent.title' | translate }}</div>

  <div class="agent-name mat-title">{{ agent.firstName + ' ' + agent.lastName }}</div>

  <img
    *ngIf="agent.profileImageUrls?.THUMBNAIL"
    src="{{ agent.profileImageUrls?.THUMBNAIL }}"
    [attr.alt]="'agent.imageCaption' | translate"
  />

  <div class="details">
    <div *ngIf="agent.mobileNumber" class="phone detail">
      <mat-icon>phone</mat-icon>
      {{ agent.mobileNumber }}
    </div>
    <div *ngIf="agent.email" class="detail">
      <mat-icon>email</mat-icon>
      {{ agent.email }}
    </div>
  </div>
</div>
