import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerApp } from './customer-app';

import { CustomerAppService } from './customer-app.service';

@Injectable()
export class AppDetailsResolver  {
  constructor(private appService: CustomerAppService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<CustomerApp> {
    return this.appService.getAppDetails(route.data.appId).pipe(take(1));
  }
}
