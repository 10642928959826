import { JsonObject, JsonProperty } from 'json2typescript';
import { Agent, BusinessObject, INIT_BY_JSON_2_TS } from '@drklein-pk/customer-core-lib';
import { LazyGetter } from 'lazy-get-decorator';

type InviteStatus = 'WAITING_FOR_CONFIRMATION' |
  'WAITING_FOR_TWO_FACTOR_CODE' |
  'FINISHED' |
  'INVALIDATED' |
  'EXPIRED' |
  'FAILED';

@JsonObject('Invite')
export class Invite extends BusinessObject {

  @JsonProperty('status', String)
  public status: InviteStatus = INIT_BY_JSON_2_TS;

  @JsonProperty('redactedMobilePhone', String, true)
  public redactedMobilePhone?: string = undefined;

  @JsonProperty('field', String, true)
  public field?: string = undefined;

  @LazyGetter()
  public get invitedBy(): Agent {
    return this._embedded?.invitedByEmployee;
  }
}
