import { Injectable } from '@angular/core';


import { UserService } from '@drklein-pk/customer-core-lib';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserInfoResolver  {
  constructor(private userService: UserService) {
  }

  resolve(): Observable<string | null> {
    return this.userService.getUserId(true).pipe(catchError(() => of(null)));
  }
}
