import { Component, Input } from '@angular/core';
import { Agent } from '@drklein-pk/customer-core-lib';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss'],
})
export class AgentComponent {

  @Input() agent!: Agent;

}
