import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationService } from '../../../core/navigation.service';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
})
export class BurgerMenuComponent implements OnDestroy {

  public menuOpen = false;

  private _isDestroyed: Subject<void> = new Subject<void>();

  constructor(private navigationService: NavigationService) {
    this.navigationService.navigationOpen$.pipe(takeUntil(this._isDestroyed)).subscribe((isOpen) => {
      this.menuOpen = isOpen;
    });
  }

  public ngOnDestroy(): void {
    this._isDestroyed.next();
    this._isDestroyed.complete();
  }
}
