import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {JsonConvert} from 'json2typescript';
import {map, tap} from 'rxjs/operators';
import {Invite} from './invite.model';

interface InviteState {
  state: 'COMPLETED' | 'NOT_COMPLETED';
  confirmationUrl?: string;
}

const ALLOW_UNAUTHENTICATED = {
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'X-Allow-Unauthenticated': 'true'
  }
};

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  private jsonConvert = new JsonConvert();

  private _inviteAccepted = false;

  constructor(private http: HttpClient) { }

  fetch(id: string): Observable<Invite> {
    return this.http.get(`/api/c-customerservice/v1/invitations/${id}`, ALLOW_UNAUTHENTICATED).pipe(
      map(response => this.jsonConvert.deserializeObject(response, Invite))
    );
  }

  sendTwoFactor(id: string, token: string): Observable<Invite> {
    return this.http.post(`/api/c-customerservice/v1/invitations/${id}/steps/send-code`, {
      token
    }).pipe(
      map(response => this.jsonConvert.deserializeObject(response, Invite))
    );
  }

  confirm(id: string, token: string, twoFactorCode: string): Observable<Invite> {
    return this.http.post(`/api/c-customerservice/v1/invitations/${id}/steps/confirm`, {
      token,
      twoFactorCode
    }).pipe(
      map(response => this.jsonConvert.deserializeObject(response, Invite))
    );
  }

  inviteAccepted(): Observable<InviteState> {
    if (this._inviteAccepted) {
      return of({ state: 'COMPLETED' });
    }
    return this.http.get<InviteState>('/api/c-customerservice/v1/invitations/state', ALLOW_UNAUTHENTICATED).pipe(
      tap((state) => this._inviteAccepted = state.state === 'COMPLETED'),
    );
  }
}
