import { Component, Input } from '@angular/core';
import { UserInfo } from '@drklein-pk/customer-core-lib';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NavigationService } from '../../core/navigation.service';
import { CustomerNameService } from '../customer-name.service';
import { Router } from '@angular/router';

@Component({
  selector: 'customer-shell-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent {
  public customerName$: Observable<string>;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private customerNameService: CustomerNameService,
  ) {
    this.customerName$ = customerNameService.customerName$;
  }

  @Input()
  set userInfo(newValue: UserInfo) {
    this.customerNameService.setCurrentUser(newValue);
  }

  public onOpenMenu(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.navigationService.toggleNavigation();
  }

  public onLogoClick() {
    if (this.navigationActive) {
      this.navigationService.initialRoute$.pipe(take(1)).subscribe((mainRoute) => {
        window.location.hash = mainRoute;
      });
    }
  }

  get navigationActive(): boolean {
    return !this.router.url.startsWith('/invite/');
  }
}
