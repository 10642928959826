import { Injectable } from '@angular/core';
import { fromEvent, merge, mergeMap, Observable, of, ReplaySubject } from 'rxjs';
import { BasicCustomer, CustomerService, UserInfo } from '@drklein-pk/customer-core-lib';
import { delay, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const CUSTOMER_UPDATED_DELAY_MS = 500;

@Injectable({
  providedIn: 'root',
})
export class CustomerNameService {
  public customerName$: Observable<string>;

  private currentUser$: ReplaySubject<UserInfo> = new ReplaySubject<UserInfo>(1);

  constructor(
    private translateService: TranslateService,
    customerService: CustomerService,
  ) {
    this.customerName$ = merge(this.currentUser$, this.replayLastUserOnEvent()).pipe(
      switchMap((userInfo) => customerService.getCustomerByUid(userInfo.id, true)),
      mergeMap((customer) => this.buildCustomerName(customer)),
      shareReplay(1)
    );
  }

  public setCurrentUser(user: UserInfo) {
    if (user) {
      this.currentUser$.next(user);
    }
  }

  private replayLastUserOnEvent(): Observable<UserInfo> {
    return fromEvent(window, 'message').pipe(
      filter((message) => (message as MessageEvent).data?.type === 'portal-customer-data-changed'),
      withLatestFrom(this.currentUser$),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([, lastUser]) => lastUser),
      delay(CUSTOMER_UPDATED_DELAY_MS)
    );
  }

  private buildCustomerName(customer: BasicCustomer): Observable<string> {
    const salutation = customer._embedded && customer._embedded.salutation ? customer._embedded.salutation.name : '';
    const firstName = customer.firstName ? customer.firstName : '';
    const lastName = customer.lastName ? customer.lastName : '';
    if (!salutation) {
      return of(`${firstName} ${lastName}`.trim());
    }
    return this.translateService.get(`customerName.salutation.${salutation}`).pipe(
      map((salutationString) => {
        return `${salutationString} ${firstName} ${lastName}`.trim();
      }),
    );

  }
}

// for the string extractor to pick up
_('customerName.salutation.Frau');
_('customerName.salutation.Herr');
