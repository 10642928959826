import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { UserService } from '@drklein-pk/customer-core-lib';

@Injectable()
export class NavigationService {
  public navigationOpen$: Observable<boolean>;

  private _navigationIsOpen: BehaviorSubject<boolean>;

  public initialRoute$: Observable<string>;

  constructor(userService: UserService) {
    this._navigationIsOpen = new BehaviorSubject<boolean>(false);
    this.navigationOpen$ = this._navigationIsOpen.asObservable();
    this.initialRoute$ = userService.getUserId(true).pipe(
      filter((userId) => !!userId),
      take(1),
      map(() => '/projects'),
      shareReplay(1)
    );
  }

  public openNavigation() {
    this._navigationIsOpen.next(true);
  }

  public closeNavigation() {
    this._navigationIsOpen.next(false);
  }

  public toggleNavigation() {
    this._navigationIsOpen.next(!this._navigationIsOpen.getValue());
  }
}
