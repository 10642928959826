import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { LoadingSpinnerComponent } from './loading-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private overlayRef?: OverlayRef;

  private isShowingSpinner: boolean;

  constructor(private injector: Injector, private overlay: Overlay) {
    this.isShowingSpinner = false;
  }

  public showSpinner(show: boolean) {
    if (show && this.isShowingSpinner) {
      return;
    }

    /**
     * The use of a Promise is needed here, because 'showSpinner(...)' can be called from 'AfterViewInit' in some cases.
     * The would lead to 'Expression has changed after view init...'. With the help of a Promise the display of the overlay
     * will be delayed to the next update tick of the browsers rendering engine.
     */
    Promise.resolve().then(() => {
      if (show) {
        this.isShowingSpinner = true;
        this.overlayRef = this.overlay.create({
          scrollStrategy: this.overlay.scrollStrategies.block(),
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
          hasBackdrop: false,
        });
        this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
      } else if (this.overlayRef) {
        this.overlayRef.dispose();
        this.isShowingSpinner = false;
      }
    });
  }
}
