/* eslint-disable */
import { CoreConfig } from '@drklein-pk/customer-core-lib';
import { InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';

// defined by webpack.DefinePlugin
declare var __VERSION__: string;

export const APP_CONFIG = new InjectionToken('app_config');

// eslint-disable-next-line
export interface AppConfig extends CoreConfig {}

export function configFactory(): AppConfig {
  return {
    clientId: 'customer-portal',
    customerApiUrl: '/api/c-customerservice/v1/customers',
    agentApiUrl: '/api/c-agentservice',
    sentryDsn: environment.sentryDsn,
    release: __VERSION__,
    isPrimaryApplication: true,
  };
}
