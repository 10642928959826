import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerAppService } from './customer-app.service';
import { NavigationService } from './navigation.service';
import { UserInfoResolver } from './user-info.resolver';
import { AppDetailsResolver } from './app-details-resolver.service';
import { AuthGuard } from './auth-guard';
import { InitialNavigationComponent } from './initial-navigation.component';

function throwIfModuleAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [InitialNavigationComponent],
  providers: [CustomerAppService, NavigationService, UserInfoResolver, AppDetailsResolver, AuthGuard],
})
export class CoreModule {
  /**
   * Prevent the CoreModule from being imported in any other module than the app.module
   * See "https://angular.io/guide/styleguide" -> "Prevent re-import of the core module"
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfModuleAlreadyLoaded(parentModule, 'CoreModule');
  }
}
