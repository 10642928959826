import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { NavigationService } from './navigation.service';

@Component({
  selector: 'customer-initial-navigation',
  template: '',
})
export class InitialNavigationComponent {
  constructor(navigationService: NavigationService) {
    navigationService.initialRoute$.pipe(take(1)).subscribe((initialRoute) => {
      // if we use router.navigateByUrl we can get an "Navigation ID x is not equal to the current navigation id y" error
      // because we navigate while navigation is ongoing (see i.e. https://stackoverflow.com/a/42802182/20247). There
      // seems to be no nicer way around it.
      window.location.hash = initialRoute;
    });
  }
}
